import "./GymCoachComponent.scss";
import CoachVector from "images/CoachVector.svg";
import PassVeryHeavy from "images/PassVeryHeavy.svg";

interface IGymCoachComponent {
  image: string;
  alt: string;
  description: string;
  phoneNumber: string;
  isDefaultCoach?: boolean;
}

const GymCoachComponent = ({
  image,
  alt,
  description,
  phoneNumber,
  isDefaultCoach,
}: IGymCoachComponent) => {
  return (
    <section>
      <div className="gymCoachComponentWrapper">
        <img src={image} alt={alt} loading="lazy" className="gymCoachImage" />
        <div className="descriptionComponent">{description}</div>
        <a href={`tel:${phoneNumber}`} className="phoneNumberComponent">
          {phoneNumber}
        </a>
        {isDefaultCoach && (
          <img
            className="defaultCoachIcon"
            src={PassVeryHeavy}
            alt={`Default Coach icon`}
          />
        )}
      </div>
      <img
        className="coachVector"
        src={CoachVector}
        alt="Bottom Coach Component Vector"
      />
    </section>
  );
};

export default GymCoachComponent;
