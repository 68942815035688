import "./MobileMenu.scss";
import CentrumSilyMobileLogo from "images/CentrumSilyMobileLogo.svg";
import RightPart from "images/MobileMenuRightPart.svg";
import LeftPart from "images/MobileMenuLeftPart.svg";
import GalleryIcon from "images/MobileMenuGalleryIcon.svg";
import MainPageIcon from "images/MobileMenuMainPageIcon.svg";
import PortalIcon from "images/MobileMenuPortalIcon.svg";
import { Link, useLocation } from "react-router-dom";

const MobileMenu = () => {
  const location = useLocation();

  const navigateToPortal = () => {
    window.location.replace("https://centrumsily.gymmanager.io");
  };

  return (
    <div className="mobileMenuWrapper">
      <img
        src={LeftPart}
        alt="Mobile part left"
        width={"50%"}
        className="mobileMenuVectorElementsRight"></img>
      <img
        src={CentrumSilyMobileLogo}
        alt="Centrum sily mobile logo"
        className="mobileMenuCentrumSilyLogo"
        height={"111px"}
      />
      <img
        src={RightPart}
        alt="Mobile part left"
        width={"50%"}
        className="mobileMenuVectorElementsLeft"></img>
      <div className="moileMenuTextContents">
        {location.pathname == "/" ? (
          <Link to="/gallery" className="mobileMenuGalleryLink">
            <img src={GalleryIcon} alt="Gallery icon" height={"25px"} />
            <div className="mobileMenuText">Galeria</div>
          </Link>
        ) : (
          <Link to="/" className="mobileMenuGalleryLink">
            <img
              src={MainPageIcon}
              alt="Centrum siły main page"
              height={"25px"}
            />
            <div className="mobileMenuText">Strona główna</div>
          </Link>
        )}
        <div onClick={navigateToPortal}>
          <img src={PortalIcon} alt="Portal icon" height={"25px"} />
          <div className="mobileMenuText">Portal</div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
