import "./Footer.scss";
import Logo from "images/LogoNoBackground.svg";
import FooterVector from "images/FooterGrayVector.svg";
import VectorUnderline from "pages/Components/VectorUnderline/VectorUnderline";
import RedPhoneIcon from "images/RedPhoneIcon.svg";
import FacebookIcon from "images/FacebookIcon.svg";
import InstagramIcon from "images/InstagramIcon.svg";

const Footer = () => {
  const navigateToInstagram = () => {
    window.location.replace("https://www.instagram.com/centrumsily/");
  };

  const navigateToFacebook = () => {
    window.location.replace("https://www.facebook.com/centrumsily");
  };

  return (
    <div className="pageFooter" id="footer">
      <img src={FooterVector} alt="Footer vector" width={"100%"} />
      <div className="footerWrapper">
        <div className="footerInnerWrapper">
          <img
            src={Logo}
            width={"250px"}
            alt="Footer big centrum sily logo"
            className="footerLogo"
          />
          <VectorUnderline />
          <div className="footerConctactUs">Skontaktuj się z nami</div>
          <div className="footerConctactUsNumbers">
            <a href="tel:507041253" className="footerSingleNumber">
              <img src={RedPhoneIcon} alt="Phone icon" />
              507 041 253 Joanna
            </a>
            <a href="tel:693063256" className="footerSingleNumber">
              <img src={RedPhoneIcon} alt="Phone icon" />
              693 063 256 Leszek
            </a>
            <div className="footerFollowUs">Bądź z nami na bieżąco</div>
            <div>
              <img
                src={FacebookIcon}
                alt="Facebook icon"
                width={"60px"}
                className="footerFollowUsIcon"
                onClick={navigateToFacebook}
              />
              <img
                src={InstagramIcon}
                alt="Instagram icon"
                width={"60px"}
                className="footerFollowUsIcon"
                onClick={navigateToInstagram}
              />
            </div>
          </div>
        </div>
        <div className="footerCopyright">
          © Copyright{" "}
          <span className="footerCopyrightRed">CENTRUM_SIŁY_2025</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
