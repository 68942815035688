import GymCoachComponent from "pages/Components/GymCoachComponent/GymCoachComponent";
import "./GymCoaches.scss";
import VectorUnderline from "pages/Components/VectorUnderline/VectorUnderline";
import FirstCoach from "images/Coach1Img.webp";
import DefaultCoach from "images/DefaultCoachImg.webp";

const GymCoaches = () => {
  return (
    <section>
      <div className="gymCoachesComponent">
        <div className="gymCoachesTitle">Dostępni trenerzy</div>
        <VectorUnderline noLeft />
      </div>
      <div className="gymCoachesWrapper">
        <GymCoachComponent
          image={FirstCoach}
          alt="Karolina Coach"
          description="Trenerka Karolina"
          phoneNumber="789 246 311"
        />
        <GymCoachComponent
          image={DefaultCoach}
          isDefaultCoach
          alt="Andrzej Coach"
          description="Trener Andrzej"
          phoneNumber="537 240 394"
        />
        <GymCoachComponent
          image={DefaultCoach}
          isDefaultCoach
          alt="Bartek Coach"
          description="Trener Bartek"
          phoneNumber="730 273 580"
        />
        <GymCoachComponent
          image={DefaultCoach}
          isDefaultCoach
          alt="Leszek Coach"
          description="Trener Leszek"
          phoneNumber="693 063 256"
        />
      </div>
    </section>
  );
};

export default GymCoaches;
