import VectorUnderline from "pages/Components/VectorUnderline/VectorUnderline";
import "./GymEntranceInstruction.scss";

const GymEntranceInstruction = () => {
  return (
    <div className="gymEntracneIntstuction">
      <div className="gymEntracneIntstuctionTitle">Aby wejść na siłownie:</div>
      <VectorUnderline />
      <div
        className="gymEntracneIntstuctiontText"
        style={{ marginTop: "50px" }}>
        <span className="gymEntranceInstructionRedItem">|</span> Zaloguj się na
        swoje konto
      </div>
      <div className="gymEntracneIntstuctiontText">
        <span className="gymEntranceInstructionRedItem">|</span> Wybierz Twój
        kod QR
      </div>
      <div className="gymEntracneIntstuctiontText">
        <span className="gymEntranceInstructionRedItem">|</span> Zeskanuj kod na
        urządzeniu przed bramką
      </div>
      <div className="gymEntracneIntstuctiontText">
        <span className="gymEntranceInstructionRedItem">|</span> Masz 5 sekund
        na wejście przez kołowrót
      </div>
    </div>
  );
};

export default GymEntranceInstruction;
